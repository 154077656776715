<template>
  <div>
    <div class="list clear">
      <ul class="listBox">
        <li v-for="(item, idx) in detailColumns" :key="idx">
          <span>{{ item.title }}</span>
          <i v-if="item.key === 'mchType'">
            {{ detail.mchType | filterMchType(mchType) }}
          </i>
          <i v-else-if="item.key === 'bussinessType'">
            {{ detail.bussinessType | filterBussinessType(industry) }}
          </i>
          <i v-else-if="item.key === 'licenceBegin'">
            {{ detail.licenceBegin }}
            <span v-if="detail.licenceBegin && detail.licenceEnd">至</span>
            {{ detail.licenceEnd == "forever" ? "长期" : detail.licenceEnd }}
          </i>
          <i v-else-if="item.key === 'legalPersonCemStartDate'">
            {{ detail.legalPersonCemStartDate }}
            <span v-if="detail.licenceBegin && detail.legalPersonCemEndDate">至</span>
            {{ detail.legalPersonCemEndDate == "forever" ? "长期" : detail.legalPersonCemEndDate }}
          </i>
          <i v-else-if="item.key === 'registryProvince'">
            {{ detail.registryProvince | filterProvince }}
            {{ detail.registryCity && '-' }}
            {{ detail.registryCity | filterCity(detail.registryProvince) }}
            {{ detail.registryDistrict && '-' }}
            {{
              detail.registryDistrict
                | filterDistrict(detail.registryProvince, detail.registryCity)
            }}
          </i>
          <i v-else-if="item.showImg" class="ellipsis">
            <a v-if="detail[item.key]" @click="showImgModal(detail[item.key])">点击预览</a>
          </i>
          <i v-else style="display: block">
            <Tooltip
              v-if="item.tooltip && detail[item.key]"
              effect="light"
              style="height: 20px"
              :content="detail[item.key]"
              placement="top-start">
              <span class="ellipsis" style="display: block; width: 300px">{{ detail[item.key] }}</span>
            </Tooltip>
            <span v-else>{{ detail[item.key] }}</span>
          </i>
        </li>
      </ul>
    </div>
    <!-- 查看图片 -->
    <BigImage :show-image.sync="showBigImg" :src="bigImgSrc" />
  </div>
</template>

<script>
import BigImage from '@/components/BigImage'
import address from "@/utils/address.json"
import industry from "@/utils/industry.json"
export default {
  components: { BigImage },
  filters: {
    filterMchType (val, options) {
      if (!val) { return '' }
      let result = options.filter(item => item.value === val)
      return (result.length > 0 && result[0].title) || ''
    },
    filterBussinessType (val, options) {
      if (!val) { return '' }
      let result = options.filter(item => item.id === val)
      return (result.length > 0 && result[0].name) || ''
    },
    filterProvince (val) {
      const obj = address.find((v) => v.provinceCode == val) || {}
      return obj.province
    },
    filterCity (val, provinceCode) {
      if (!provinceCode) { return }
      const province = address.find((v) => v.provinceCode == provinceCode).citys
      return province.find((v) => v.cityCode == val).city
    },
    filterDistrict (val, provinceCode, cityCode) {
      if (!provinceCode || !cityCode) { return }
      const province = address.find((v) => v.provinceCode == provinceCode).citys
      const city = province.find((v) => v.cityCode == cityCode).countys
      return city.find((v) => v.countyCode == val).county
    }
  },
  data () {
    return {
      detailColumns: [
        { title: '商户名称', key: 'mchName', tooltip: true, showImg: false },
        { title: '商户类型', key: 'mchType' },
        { title: '商户编号', key: 'mchId' },
        { title: '账户编号', key: 'accountId' },
        { title: '行业类别', key: 'bussinessType' },
        { title: '商户证件编号', key: 'licenceNo' },
        { title: '营业执照注册地址', key: 'companyAddress', tooltip: true },
        { title: '营业执照有效期', key: 'licenceBegin' },
        { title: '经营地址', key: 'registryProvince' },
        { title: '经营详细地址', key: 'registryAddress', tooltip: true },
        { title: '营业执照照片', key: 'licencePicture', showImg: true },
        { title: '法人姓名', key: 'legalPersonName' },
        { title: '法人手机号码', key: 'legalPersonPhone' },
        { title: '法人身份证号码', key: 'idcardNo' },
        { title: '法人证件有效期', key: 'legalPersonCemStartDate' },
        { title: '法人身份证人像照片', key: 'idcardReversePicture', showImg: true },
        { title: '法人身份证国徽照片', key: 'idcardNormalPicture', showImg: true },
        { title: '联系号码', key: 'contactTel' },
        { title: '联系邮箱', key: 'contactEmail', tooltip: true },
        { title: '开户许可证编号', key: 'accountOpeningLicenceNo', tooltip: true },
        { title: '开户许可证照片', key: 'accountOpeningLicense', showImg: true },
        { title: '创建时间', key: 'createTime', tooltip: true },
        { title: '审核时间', key: 'checkTime', tooltip: true },
        { title: '最后修改时间', key: 'updateTime', tooltip: true },
        { title: '最后修改人', key: 'lastUpdatedUser', tooltip: true },
      ],
      mchType: [
        { title: "企业", value: "ENTERPRISE" },
        { title: "个体工商户", value: "INDIVIDUAL_BUSINESS" }
      ],
      industry,
      // 查看图片大图
      showBigImg: false,
      bigImgSrc: null
    }
  },
  props: {
    detail: {
      type: Object,
      default: {},
    },
  },
  methods: {
    // 查看图片
    showImgModal (cover) {
      this.showBigImg = true
      this.bigImgSrc = cover
    },
  },
};
</script>

<style lang="less" scoped>
</style>