<template>
  <div id="mian" class="container">
    <!-- <Tabs v-model="curTab" @on-click="handleClickTab">
      <TabPane label="基本信息" name="tab1"></TabPane>
      <TabPane v-if="type !== 'add'" label="结算信息" name="tab2"></TabPane>
      <TabPane label="关联分账方" name="tab3"></TabPane>
      <TabPane v-if="type !== 'add'" label="电子账簿" name="tab4"></TabPane>
      <TabPane v-if="type !== 'add'" label="产品信息" name="tab5"></TabPane>
      <TabPane label="补充材料" name="tab6"></TabPane>
    </Tabs> -->
    <header class="header">
      <ul class="tab-tilte">
        <li
          :class="{ active: curTab == 'tab1' }"
          @click="handleClickTab('tab1')"
        >
          <span v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/SHOW'"
            >基本信息</span
          >
        </li>
        <li
          v-if="type !== 'add'"
          :class="{ active: curTab == 'tab2' }"
          @click="handleClickTab('tab2')"
        >
          <span v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/SETTLE'"
            >结算信息</span
          >
        </li>
        <li
          :class="{ active: curTab == 'tab3' }"
          @click="handleClickTab('tab3')"
        >
          <span v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNT/SHOW'"
            >关联分账方</span
          >
        </li>
        <li
          v-if="type !== 'add'"
          :class="{ active: curTab == 'tab4' }"
          @click="handleClickTab('tab4')"
        >
          <span v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK'"
            >电子账簿</span
          >
        </li>
        <li
          v-if="type !== 'add'"
          :class="{ active: curTab == 'tab5' }"
          @click="handleClickTab('tab5')"
        >
          <span v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/PRODUCT'"
            >产品信息</span
          >
        </li>
        <li
          :class="{ active: curTab == 'tab6' }"
          @click="handleClickTab('tab6')"
        >
          <span v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ATTACH/SHOW'"
            >补充材料</span
          >
        </li>
      </ul>
    </header>
    <!-- 基本信息 -->
    <div v-if="curTab === 'tab1'" class="table">
      <div class="desc">
        <h5 class="title float_left">基本信息</h5>
        <button
          v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ATTACH/EDIT'"
          v-if="
            type === 'detail' &&
            detail.checkStatus !== 'PASS' &&
            activateStatusSwitch !== 'FROZEN'
          "
          class="ivu-btn edit-btn"
          @click="handleClickEdit"
        >
          编辑
        </button>
      </div>
      <template v-if="type === 'detail'">
        <Info :detail="detail"></Info>
        <div class="status">
          <span class="label">审核状态</span>
          <p>{{ checkStatus[detail.checkStatus] }}</p>
          <span
            v-if="detail.checkStatus === 'REJECT'"
            class="check-msg ellipsis"
            >{{ detail.checkMsg || "" }}</span
          >
        </div>
        <div class="status">
          <span class="label">商户状态</span>
          <p>{{ activateStatus[detail.activateStatus] }}</p>
        </div>
      </template>
      <template v-if="['edit', 'add'].includes(type)">
        <div class="tab1-input">
          <Form
            ref="formValidate"
            class="form"
            :model="formData"
            :label-width="80"
          >
            <FormItem class="form-item" prop="mchName">
              <span class="tab-label"><i>*</i>商户名称 </span>
              <Input
                v-model="formData.mchName"
                class="inputs"
                size="large"
                placeholder="输入商户名称"
              ></Input>
            </FormItem>
            <FormItem class="form-item" prop="mchType">
              <span class="tab-label"><i>*</i>商户类型 </span>
              <Select
                v-model="formData.mchType"
                size="large"
                placeholder="选择商户类型"
              >
                <Option
                  v-for="item in mchType"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.title }}</Option
                >
              </Select>
            </FormItem>
            <FormItem class="form-item" prop="bussinessType">
              <span class="tab-label"><i>*</i>行业类别 </span>
              <Select
                v-model="formData.bussinessType"
                size="large"
                placeholder="选择行业类别"
              >
                <Option
                  v-for="item in industrysType"
                  :value="item.id"
                  :key="item.name"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
            <FormItem class="form-item" prop="licenceNo">
              <span class="tab-label"><i>*</i>营业执照编号 </span>
              <Input
                v-model="formData.licenceNo"
                class="inputs"
                size="large"
                placeholder="输入营业执照编号"
              ></Input>
            </FormItem>
            <FormItem class="form-item" prop="companyAddress">
              <span class="tab-label"><i>*</i>营业执照注册地址 </span>
              <Input
                v-model="formData.companyAddress"
                class="inputs"
                size="large"
                placeholder="输入营业执照注册地址"
              ></Input>
            </FormItem>
            <FormItem class="form-item form-address" prop="licenceBegin">
              <span class="tab-label"><i>*</i>营业执照有效期 </span>
              <div class="form-date-picker">
                <FormItem
                  style="flex: 1"
                  :class="[placementLeft === 'bottom-end' && placementLeft]"
                >
                  <DatePicker
                    v-model="formData.licenceBegin"
                    format="yyyy-MM-dd"
                    class="date-picker"
                    type="date"
                    size="large"
                    placeholder="开始日期"
                  ></DatePicker>
                </FormItem>
                <span class="split">至</span>
                <FormItem
                  style="flex: 1"
                  prop="licenceEnd"
                  :class="[placementRight === 'bottom-end' && placementRight]"
                >
                  <DatePicker
                    v-model="formData.licenceEnd"
                    format="yyyy-MM-dd"
                    :options="options"
                    class="date-picker"
                    type="date"
                    size="large"
                    placeholder="结束日期"
                  ></DatePicker>
                </FormItem>
              </div>
            </FormItem>
            <FormItem class="form-item form-address" prop="registryProvince">
              <span class="tab-label"><i>*</i>经营地址 </span>
              <div class="form-date-picker">
                <FormItem style="flex: 1">
                  <Select
                    v-model="formData.registryProvince"
                    size="large"
                    placeholder="选择省"
                    filterable
                    @on-change="getAddress(1)"
                  >
                    <Option
                      v-for="item in provinceOption"
                      :value="item.provinceCode"
                      :key="item.provinceCode"
                      >{{ item.province }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem style="flex: 1" prop="registryCity">
                  <Select
                    v-model="formData.registryCity"
                    size="large"
                    placeholder="选择市"
                    filterable
                    @on-change="getAddress(2)"
                  >
                    <Option
                      v-for="item in cityOption"
                      :value="item.cityCode"
                      :key="item.cityCode"
                      >{{ item.city }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem style="flex: 1" prop="registryDistrict">
                  <Select
                    v-model="formData.registryDistrict"
                    size="large"
                    placeholder="选择区"
                    filterable
                  >
                    <Option
                      v-for="item in countyOption"
                      :value="item.countyCode"
                      :key="item.countyCode"
                      >{{ item.county }}</Option
                    >
                  </Select>
                </FormItem>
              </div>
            </FormItem>
            <FormItem class="form-item" prop="registryAddress">
              <span class="tab-label"><i>*</i>经营详细地址 </span>
              <Input
                v-model="formData.registryAddress"
                class="inputs"
                size="large"
                placeholder="输入经营详细地址"
              ></Input>
            </FormItem>
            <FormItem class="form-item" prop="legalPersonName">
              <span class="tab-label"><i>*</i>法人姓名 </span>
              <Input
                v-model="formData.legalPersonName"
                class="inputs"
                size="large"
                placeholder="输入法人姓名"
              ></Input>
            </FormItem>
            <FormItem class="form-item" prop="legalPersonPhone">
              <span class="tab-label"><i>*</i>法人手机号码 </span>
              <Input
                v-model="formData.legalPersonPhone"
                maxlength="11"
                class="inputs"
                size="large"
                placeholder="输入法人手机号码"
              ></Input>
            </FormItem>
            <FormItem class="form-item" prop="idcardNo">
              <span class="tab-label"><i>*</i>法人身份证号码 </span>
              <Input
                v-model="formData.idcardNo"
                class="inputs"
                size="large"
                placeholder="输入法人手机号码"
              ></Input>
            </FormItem>
            <FormItem
              class="form-item form-address"
              prop="legalPersonCemStartDate"
            >
              <span class="tab-label"><i>*</i>法人证件有效期 </span>
              <div class="form-date-picker">
                <FormItem
                  style="flex: 1"
                  prop="legalPersonCemStartDate"
                  :class="[placementLeft === 'bottom-end' && placementLeft]"
                >
                  <DatePicker
                    v-model="formData.legalPersonCemStartDate"
                    class="date-picker"
                    type="date"
                    size="large"
                    placeholder="开始日期"
                  ></DatePicker>
                </FormItem>
                <span class="split">至</span>
                <FormItem
                  style="flex: 1"
                  prop="legalPersonCemEndDate"
                  :class="[placementRight === 'bottom-end' && placementRight]"
                >
                  <DatePicker
                    v-model="formData.legalPersonCemEndDate"
                    class="date-picker"
                    :options="options"
                    type="date"
                    size="large"
                    placeholder="结束日期"
                  ></DatePicker>
                </FormItem>
              </div>
            </FormItem>
            <FormItem class="form-item" prop="contactTel">
              <span class="tab-label"><i>*</i>联系号码 </span>
              <Input
                v-model="formData.contactTel"
                maxlength="11"
                class="inputs"
                size="large"
                placeholder="输入联系号码"
              ></Input>
            </FormItem>
            <FormItem class="form-item" prop="contactEmail">
              <span class="tab-label"><i>*</i>联系邮箱 </span>
              <Input
                v-model="formData.contactEmail"
                class="inputs"
                size="large"
                placeholder="输入联系邮箱"
              ></Input>
            </FormItem>
            <FormItem class="form-item">
              <span class="tab-label"
                ><i v-if="formData.mchType === 'ENTERPRISE'">*</i>开户许可证编号
              </span>
              <Input
                v-model="formData.accountOpeningLicenceNo"
                class="inputs"
                size="large"
                placeholder="输入开户许可证编号"
              ></Input>
            </FormItem>
          </Form>
        </div>
        <div class="tab1-upload">
          <div class="upload-form-item" prop="licencePicture">
            <span class="tab-label"><i>*</i>营业执照照片 </span>
            <Upload
              v-model="formData.licencePicture"
              type="drag"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :action="uploadUrl"
              :on-success="licencePictureSuccess"
            >
              <div class="upload-img">
                <img
                  v-if="formData.licencePicture"
                  :src="formData.licencePicture"
                  class="avatar"
                />
                <Icon
                  v-else
                  type="ios-add"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <Icon
                  v-if="formData.licencePicture"
                  type="md-close"
                  size="25"
                  class="icon-delete"
                  style="color: #3399ff"
                  @click.stop="formData.licencePicture = ''"
                ></Icon>
              </div>
            </Upload>
          </div>
          <div class="upload-form-item" prop="idcardNormalPicture">
            <span class="tab-label"><i>*</i>法人身份证国徽照片 </span>
            <Upload
              v-model="formData.idcardNormalPicture"
              type="drag"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :action="uploadUrl"
              :on-success="idcardNormalPictureSuccess"
            >
              <div class="upload-img">
                <img
                  v-if="formData.idcardNormalPicture"
                  :src="formData.idcardNormalPicture"
                  class="avatar"
                />
                <Icon
                  v-else
                  type="ios-add"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <Icon
                  v-if="formData.idcardNormalPicture"
                  type="md-close"
                  size="25"
                  class="icon-delete"
                  style="color: #3399ff"
                  @click.stop="formData.idcardNormalPicture = ''"
                ></Icon>
              </div>
            </Upload>
          </div>
          <div class="upload-form-item" prop="idcardReversePicture">
            <span class="tab-label"><i>*</i>法人身份证人像照片 </span>
            <Upload
              v-model="formData.idcardReversePicture"
              type="drag"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :action="uploadUrl"
              :on-success="idcardReversePictureSuccess"
            >
              <div class="upload-img">
                <img
                  v-if="formData.idcardReversePicture"
                  :src="formData.idcardReversePicture"
                  class="avatar"
                />
                <Icon
                  v-else
                  type="ios-add"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <Icon
                  v-if="formData.idcardReversePicture"
                  type="md-close"
                  size="25"
                  class="icon-delete"
                  style="color: #3399ff"
                  @click.stop="formData.idcardReversePicture = ''"
                ></Icon>
              </div>
            </Upload>
          </div>
          <div class="upload-form-item" prop="accountOpeningLicense">
            <span class="tab-label"><i>*</i>开户许可证照片 </span>
            <Upload
              v-model="formData.accountOpeningLicense"
              type="drag"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :action="uploadUrl"
              :on-success="accountOpeningLicenseSuccess"
            >
              <div class="upload-img">
                <img
                  v-if="formData.accountOpeningLicense"
                  :src="formData.accountOpeningLicense"
                  class="avatar"
                />
                <Icon
                  v-else
                  type="ios-add"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <Icon
                  v-if="formData.accountOpeningLicense"
                  type="md-close"
                  size="25"
                  class="icon-delete"
                  style="color: #3399ff"
                  @click.stop="formData.accountOpeningLicense = ''"
                ></Icon>
              </div>
            </Upload>
          </div>
        </div>
        <div class="tab1-footer">
          <div v-if="type === 'edit'">
            <Button @click="handleClickCancel">返回</Button>
            <Button type="primary" @click="onSubmit">保存</Button>
          </div>
          <div v-if="type === 'add'">
            <Button type="primary" @click="nextStep('tab1')">下一步</Button>
          </div>
        </div>
      </template>
    </div>
    <!-- 结算信息 -->
    <div v-if="curTab === 'tab2'" class="table">
      <div class="desc">
        <h5 class="title float_left">结算信息</h5>
      </div>
      <Table :columns="settleColumns" :data="settleData"></Table>
    </div>
    <!-- 关联分账方 -->
    <div v-if="curTab === 'tab3'" class="table tab3">
      <div class="desc">
        <h5 class="title float_left">关联分账方</h5>
      </div>
      <Table :columns="accountColumns" :data="accountData">
        <template slot="action" slot-scope="{ row }">
          <a
            v-if="activateStatusSwitch !== 'FROZEN'"
            v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNT/EDIT'"
            @click="editAccount(row)"
            >编辑</a
          >
        </template>
      </Table>
      <a
        v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNT/EDIT'"
        v-if="type === 'add' && accountData.length === 0"
        class="add-user-btn"
        @click="addAccount"
        >+新增分账方信息</a
      >
      <div v-if="type === 'add'" class="tab1-footer">
        <div>
          <Button @click="curTab = 'tab1'">上一步</Button>
          <Button type="primary" @click="nextStep('tab3')">下一步</Button>
        </div>
      </div>
    </div>
    <!-- 电子账簿 -->
    <div v-if="curTab === 'tab4'" class="table">
      <div class="desc">
        <h5 class="title float_left">电子账簿信息</h5>
      </div>
      <Table :columns="accBooChannelColumns" :data="accBooChannel"></Table>
    </div>
    <!-- 平台产品信息 -->
    <div v-if="curTab === 'tab5'" class="table">
      <div class="desc">
        <h5 class="title float_left">平台产品信息</h5>
      </div>
      <Table :columns="productColumns" :data="productData">
        <template slot="mchName">
          <span>{{ detail.mchName }}</span>
        </template>
      </Table>
      <div v-if="type === 'add'" class="tab1-footer">
        <div>
          <Button @click="curTab = 'tab1'">上一步</Button>
          <Button type="primary" @click="curTab = 'tab6'">下一步</Button>
        </div>
      </div>
    </div>
    <div v-if="curTab === 'tab6'" class="table">
      <div class="desc">
        <h5 class="title float_left">补充材料</h5>
        <button
          v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ATTACH/EDIT'"
          v-if="
            type === 'detail' &&
            detail.checkStatus !== 'PASS' &&
            activateStatusSwitch !== 'FROZEN'
          "
          class="ivu-btn edit-btn"
          @click="handleClickEdit"
        >
          编辑
        </button>
      </div>
      <Attach :type="type" :formData="attachFormData"></Attach>
      <div v-if="type === 'add'" class="tab1-footer">
        <div>
          <Button @click="curTab = 'tab3'">上一步</Button>
          <Button type="primary" @click="handleClickSubmit">确定</Button>
        </div>
      </div>
      <div v-if="type === 'edit'" class="tab1-footer">
        <div>
          <Button @click="handleClickCancel">取消</Button>
          <Button type="primary" @click="handleClickSubmit">保存</Button>
        </div>
      </div>
    </div>
    <!-- 分账方信息弹窗 -->
    <Modal
      v-model="addUserShow"
      class="tab3"
      title="分账方信息"
      width="490"
      @on-cancel="addUserShow = false"
      @on-ok="sureAdd"
    >
      <Form class="tab3-form tab3-form-account" :model="mchFormData">
        <FormItem class="form-item" prop="mchName">
          <span class="tab-label"><i>*</i>商户名称 </span>
          <Input
            v-model="mchFormData.mchName"
            class="inputs"
            size="large"
            placeholder="输入商户名称"
            disabled
          ></Input>
        </FormItem>
        <FormItem class="form-item" prop="allocMchId">
          <span class="tab-label"><i>*</i>分账方编号 </span>
          <Select
            class="inputs"
            v-model="mchFormData.allocMchId"
            placeholder="选择分账方编号"
            size="large"
            tabindex="4"
          >
            <Option
              v-for="(v, i) in subNumber"
              :key="i"
              :label="v.allocMchId"
              :value="v.allocMchId"
            ></Option>
          </Select>
        </FormItem>
      </Form>
      <span slot="footer" class="dialog-footer">
        <Button class="exitBt" size="default" @click="addUserShow = false"
          >取 消</Button
        >
        <Button class="addBt" size="default" type="primary" @click="sureAdd"
          >确 定</Button
        >
      </span>
    </Modal>
  </div>
</template>

<script>
import * as imageConversion from "image-conversion";
import { URL_API } from "@/utils/apiUrl.js";
import Attach from "./components/attach";
import Info from "./components/info";
import industry from "@/utils/industry.json";
import address from "@/utils/address.json";
import { mapState, mapMutations } from "vuex";
import { formatDate } from "@/utils/common.js";
import {
  shopDetail,
  setShop,
  mchAllocEdit,
  searAlloc,
  addShop,
  putsupplementEidt,
} from "@/api/basic/fileManage.js";
export default {
  components: { Attach, Info },
  data() {
    return {
      activateStatusSwitch: "",
      curTab: "tab1",
      type: "detail",
      buttonFlag: false,
      options: {
        shortcuts: [
          {
            text: "长期",
            value() {
              return new Date("2999-01-01");
            },
          },
        ],
      },
      formData: {
        mchModel: 1, // 0单商户入网 1服务商入网
        mchName: "", //商户名称
        mchType: "", //商户类型，
        bussinessType: "", //行业类别
        licenceNo: "", //营业执照编号
        companyAddress: "", //营业执照注册地址
        licenceBegin: "", //营业执照有效期
        licenceEnd: "", //营业执照有效期
        legalPersonName: "", //法人姓名
        legalPersonPhone: "", //法人手机号码
        idcardNo: "", //法人身份证号码
        legalPersonCemStartDate: "", //法人证件有效期
        legalPersonCemEndDate: "", //法人证件有效期
        contactTel: "", //联系号码
        contactEmail: "", //联系邮箱
        accountOpeningLicenceNo: "", //开户许可证编号
        contactName: "", //取值-法人姓名

        registryProvince: "", //省
        registryCity: "", //市
        registryDistrict: "", //区
        registryAddress: "", //详细地址

        licencePicture: "", //营业执照照片URL
        idcardNormalPicture: "", //	身份证国徽照片（法人或联系人）
        idcardReversePicture: "", //	身份证人像照片（法人或联系人
        accountOpeningLicense: "", //开户许可证图片URL
      },
      attachFormData: {
        storeAuthPic: "", //店铺认证截图 //店铺信息
        storeComPic: "", //店铺货物截图
        storePayPic: "", //支付流程截图
        storeSetPic: "", //店铺结算记录截图
        storeUrl: "", //店铺链接
        appPayPic: "", //支付流程截图 //APP信息
        appComPic: "", //首页货品截图
        appAuthPic: "", //认证页截图
        appName: "", //APP名称
        appAccount: "", //APP账号
        appPasswordAPP: "", //APP密码
        mpPayPic: "", //支付流程截图 //公众号信息
        mpComPic: "", //首页货品截图
        mpAuthPic: "", //认证页截图
        mpName: "", //公众号名称
        h5PayPic: "", //支付流程截图 //H5 信息
        h5ComPic: "", //首页货品截图
        h5Url: "", //H5 链接
        h5Account: "", //H5 账号
        h5Password: "", //H5 密码
        miniPayPic: "", //支付流程截图 //小程序信息
        miniComPic: "", //首页货品截图
        miniAuthPic: "", //认证页截图
        miniName: "", //小程序名称
        businessContract: "", //代运营公司合作协议或合作说明  //代运营公司合作证明
      },
      // 基本信息详情
      detail: {},
      checkStatus: {
        WAIT: "待审核",
        PASS: "已通过",
        REJECT: "已驳回",
      },
      activateStatus: {
        NOTACTIVATE: "未激活",
        ACTIVATE: "已激活",
        FROZEN: "已冻结",
        CLOSED: "已关闭",
      },
      // 结算信息
      settleColumns: [
        { title: "账户名", key: "accountName", align: "center" },
        { title: "账号", key: "accountNo", align: "center" },
        { title: "联行号", key: "interBranchesNo", align: "center" },
        {
          title: "账户类型",
          key: "accountType",
          align: "center",
          render(h, { row }) {
            let map = {
              COMPANY: "企业",
              PERSONAL: "个人",
            };
            return h("p", map[row.accountType]);
          },
        },
      ],
      settleData: [],
      // 关联分账方
      accountColumns: [
        { title: "商户名称", key: "mchName", align: "center" },
        { title: "分账方编号", key: "allocMchId", align: "center" },
        { title: "三方分账方编号", key: "allocMchNo", align: "center" },
        { title: "操作", key: "action", align: "center", slot: "action" },
      ],
      accountData: [],
      // 电子账簿
      accBooChannelColumns: [
        { title: "户名", key: "accountName", align: "center" },
        { title: "账号", key: "accountNo", align: "center" },
        { title: "银行", key: "accountBankName", align: "center" },
        { title: "支行", key: "accountBranchBankName", align: "center" },
        { title: "开户地", key: "accountOpen", align: "center" },
      ],
      accBooChannel: [],
      // 产品信息
      productColumns: [
        {
          title: "商户名称",
          key: "mchName",
          align: "center",
          slot: "mchName",
        },
        {
          title: "产品类型",
          key: "type",
          align: "center",
          render(h, { row }) {
            let map = {
              1: "出金",
              2: "代付",
              3: "转账",
              4: "结算",
            };
            return h("p", map[row.type]);
          },
        },
        {
          title: "状态",
          key: "status",
          align: "center",
          render(h, { row }) {
            return h("p", `${Boolean(row.status) ? "已开启" : "已关闭"}`);
          },
        },
      ],
      productData: [],
      // 筛选项
      mchType: [
        { title: "企业", value: "ENTERPRISE" },
        { title: "个体工商户", value: "INDIVIDUAL_BUSINESS" },
      ],
      industrysType: industry,
      address,
      provinceOption: address,
      cityOption: [],
      countyOption: [],
      // 新增分账方信息
      addUserShow: false,
      mchFormData: {
        mchName: "",
        allocMchId: "",
      },
      subNumber: [],
      // 图片上传
      uploadUrl: URL_API.upload,

      placementRight: "bottom-start",
      placementLeft: "bottom-start",
    };
  },
  computed: {
    ...mapState({
      businessData: "business_data",
    }),
  },

  created() {
    this.type = this.$route.query.type || "detail";

    if (this.businessData) {
      const { mchDetail, mchAllocAccount, mchProfileSupplementPO } =
        this.businessData;
      this.formData = mchDetail;
      this.accountData = mchAllocAccount;
      this.attachFormData = mchProfileSupplementPO;
      this.formatData();
    }

    if (this.type === "detail") {
      this.getDetail();
    }

    const { activateStatus } = JSON.parse(sessionStorage.getItem("userInfo"));
    this.activateStatusSwitch = activateStatus;
  },

  mounted() {
    const updateDatePicker = () => {
      if (document.body.clientWidth <= 1900) {
        this.placementRight = "bottom-end";
      } else {
        this.placementRight = "bottom-start";
      }
      if (document.body.clientWidth <= 1505) {
        this.placementLeft = "bottom-end";
      } else {
        this.placementLeft = "bottom-start";
      }
    };
    updateDatePicker();
    window.onresize = () => {
      return (() => {
        updateDatePicker();
      })();
    };
  },

  destroyed() {
    if (this.type === "add") {
      let data = {
        mchDetail: this.formData,
        mchAllocAccount: this.accountData,
        mchProfileSupplementPO: this.attachFormData,
      };
      this.setBusinessData(data);
    }
  },

  methods: {
    ...mapMutations({ setBusinessData: "set_business_data" }),
    // 获取详情
    getDetail() {
      shopDetail(this.$route.query.id).then((res) => {
        if (res && res.resultStatus) {
          this.detail = res.resultData.mchDetail || {};
          this.accountData = [res.resultData.mchAllocAccount] || [];
          if (this.accountData.length > 0) {
            this.accountData[0].mchName = this.detail.mchName;
          }
          this.settleData = res.resultData.settlements || [];
          this.accBooChannel =
            (res.resultData.mchTradeChannel && [
              res.resultData.mchTradeChannel,
            ]) ||
            [];
          this.productData = res.resultData.mchFeeAccount || [];
          this.attachFormData = res.resultData.mchSupplement || {};
          if (this.detail.checkStatus === "PASS") {
            this.accountColumns = this.accountColumns.filter(
              (item) => item.key !== "action"
            );
          }
          console.log("商户详情信息", this.detail);
        }
      });
    },
    getAddress(active = 0) {
      if (active == 1) {
        this.formData.registryCity = "";
        this.formData.registryDistrict = "";
        this.cityOption = this.provinceOption.find(
          (v) => v.provinceCode == this.formData.registryProvince
        ).citys;
      } else if (active == 2) {
        this.formData.registryDistrict = "";
        this.countyOption = this.cityOption.find(
          (v) => v.cityCode == this.formData.registryCity
        ).countys;
      } else {
        if (this.formData.registryProvince) {
          this.cityOption = this.provinceOption.find(
            (v) => v.provinceCode == this.formData.registryProvince
          ).citys;
        }
        if (this.formData.registryCity) {
          this.countyOption = this.cityOption.find(
            (v) => v.cityCode == this.formData.registryCity
          ).countys;
        }
      }
    },

    // 点击导航
    handleClickTab(tab) {
      this.curTab = tab;
      if (this.type === "add") {
        if (this.curTab !== "tab1" && !this.validate("tab1")) {
          this.$nextTick(() => {
            this.curTab = "tab1";
          });
          return;
        }
        if (["tab5", "tab6"].includes(this.curTab) && !this.validate("tab3")) {
          this.$nextTick(() => {
            this.curTab = "tab3";
          });
          return;
        }
      }
      if (this.type === "edit") {
        this.type = "detail";
      }
    },
    handleClickEdit() {
      this.type = "edit";
      this.formData = Object.assign({}, this.detail);
      this.formatData();
    },
    formatData() {
      this.cityOption = this.provinceOption.find(
        (v) => v.provinceCode == this.formData.registryProvince
      ).citys;
      this.countyOption = this.cityOption.find(
        (v) => v.cityCode == this.formData.registryCity
      ).countys;
      if (this.formData.licenceEnd == "forever") {
        this.formData.licenceEnd = "2999-01-01";
      }
      if (this.formData.legalPersonCemEndDate == "forever") {
        this.formData.legalPersonCemEndDate = "2999-01-01";
      }
    },
    handleClickCancel() {
      this.type = "detail";
      this.getDetail();
    },
    // 新增商户
    handleClickSubmit() {
      if (
        this.attachFormData.storeAuthPic &&
        this.attachFormData.storeComPic &&
        this.attachFormData.storePayPic &&
        this.attachFormData.storeSetPic &&
        this.attachFormData.storeUrl
      ) {
      } else if (
        this.attachFormData.appPayPic &&
        this.attachFormData.appComPic &&
        this.attachFormData.appAuthPic &&
        this.attachFormData.appName &&
        this.attachFormData.appAccount &&
        this.attachFormData.appPasswordAPP
      ) {
      } else if (
        this.attachFormData.mpPayPic &&
        this.attachFormData.mpComPic &&
        this.attachFormData.mpAuthPic &&
        this.attachFormData.mpName
      ) {
      } else if (
        this.attachFormData.h5PayPic &&
        this.attachFormData.h5ComPic &&
        this.attachFormData.h5Url &&
        this.attachFormData.h5Account &&
        this.attachFormData.h5Password
      ) {
      } else if (
        this.attachFormData.miniPayPic &&
        this.attachFormData.miniComPic &&
        this.attachFormData.miniAuthPic &&
        this.attachFormData.miniName
      ) {
      } else if (this.attachFormData.businessContract) {
      } else {
        return this.$Message.error("补充材料至少上传一组");
      }

      let data = {
        mchDetail: this.formData,
        mchAllocAccount: this.accountData[0],
        mchProfileSupplementPO: this.attachFormData,
      };
      data.mchDetail.legalPersonCemStartDate = formatDate(
        data.mchDetail.legalPersonCemStartDate,
        "yyyy-MM-dd"
      );
      data.mchDetail.legalPersonCemEndDate = formatDate(
        data.mchDetail.legalPersonCemEndDate,
        "yyyy-MM-dd"
      );
      data.mchDetail.licenceBegin = formatDate(
        data.mchDetail.licenceBegin,
        "yyyy-MM-dd"
      );
      data.mchDetail.licenceEnd = formatDate(
        data.mchDetail.licenceEnd,
        "yyyy-MM-dd"
      );
      if (data.mchDetail.legalPersonCemEndDate == "2999-01-01") {
        data.mchDetail.legalPersonCemEndDate = "forever";
      }
      if (data.mchDetail.licenceEnd == "2999-01-01") {
        data.mchDetail.licenceEnd = "forever";
      }

      console.log("提交的数据", data);

      if (this.type === "add") {
        addShop(data).then((res) => {
          if (res && res.resultStatus) {
            this.$router.push({
              name: "businessList",
            });
            this.setBusinessData(null);
          }
        });
      }
      if (this.type === "edit") {
        putsupplementEidt(this.$route.query.id, this.attachFormData).then(
          (res) => {
            if (res && res.resultStatus) {
              this.type = "detail";
              this.$Message.success("保存成功");
              this.getDetail();
            }
          }
        );
      }
    },
    // 编辑基本信息
    onSubmit() {
      if (!this.validate("tab1")) {
        return;
      }
      console.log(this.formData.legalPersonCemEndDate);

      let data = Object.assign({}, this.formData);
      data.legalPersonCemStartDate = formatDate(
        data.legalPersonCemStartDate,
        "yyyy-MM-dd"
      );
      data.legalPersonCemEndDate = formatDate(
        data.legalPersonCemEndDate,
        "yyyy-MM-dd"
      );
      data.licenceBegin = formatDate(data.licenceBegin, "yyyy-MM-dd");
      data.licenceEnd = formatDate(data.licenceEnd, "yyyy-MM-dd");
      if (data.legalPersonCemEndDate == "2999-01-01") {
        data.legalPersonCemEndDate = "forever";
      }
      if (data.licenceEnd == "2999-01-01") {
        data.licenceEnd = "forever";
      }
      console.log("编辑后提交的商户基本信息数据", data);

      setShop(this.$route.query.id, data).then((res) => {
        if (res && res.resultStatus) {
          this.$Message.success("保存成功");
          this.type = "detail";
          this.getDetail();
        }
      });
    },

    beforeUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = ["jpg", "jpeg", "png", "bpm"].includes(
        testmsg.toLocaleLowerCase()
      );
      if (!extension) {
        this.$Message.error("上传图片支持jpg/jpeg/png/bpm格式");
      }
      if (!extension) {
        return false;
      }
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 1024).then((res) => {
          const fileOfBlob = new File(
            [res],
            `${new Date().getTime()}.${testmsg}`
          );
          resolve(fileOfBlob);
        });
      });
    },
    // 商户证件上传
    licencePictureSuccess(res) {
      if (res && res.resultStatus) {
        this.formData.licencePicture = res.resultData.fileLink;
      }
    },
    // 身份证国徽照片（法人或联系人）上传
    idcardNormalPictureSuccess(res) {
      if (res && res.resultStatus) {
        this.formData.idcardNormalPicture = res.resultData.fileLink;
      }
    },
    // 身份证人像照片（法人或联系人）上传
    idcardReversePictureSuccess(res) {
      if (res && res.resultStatus) {
        this.formData.idcardReversePicture = res.resultData.fileLink;
      }
    },
    // 开户许可证上传
    accountOpeningLicenseSuccess(res) {
      if (res && res.resultStatus) {
        this.formData.accountOpeningLicense = res.resultData.fileLink;
      }
    },

    validate(tab = "tab1") {
      let formData = this.formData;
      if (tab === "tab1") {
        formData.contactName = this.formData.legalPersonName;
        if (!formData.mchName) {
          this.$Message.error("商户名称必填");
          return false;
        }
        if (!formData.mchType) {
          this.$Message.error("商户类型必选");
          return false;
        }
        if (!formData.bussinessType) {
          this.$Message.error("行业类别必选");
          return false;
        }
        if (!formData.licenceNo) {
          this.$Message.error("营业执照编号必填");
          return false;
        }
        if (!formData.companyAddress) {
          this.$Message.error("营业执照注册地址必填");
          return false;
        }
        if (!formData.licenceBegin) {
          this.$Message.error("营业执照开始日期必选");
          return false;
        }
        if (!formData.licenceEnd) {
          this.$Message.error("营业执照结束日期必选");
          return false;
        }
        if (!formData.registryProvince) {
          this.$Message.error("省份必填");
          return false;
        }
        if (!formData.registryCity) {
          this.$Message.error("城市必填");
          return false;
        }
        if (!formData.registryDistrict) {
          this.$Message.error("区县必填");
          return false;
        }
        if (!formData.registryAddress) {
          this.$Message.error("经营详细地址必填");
          return false;
        }
        if (!formData.legalPersonName) {
          this.$Message.error("法人姓名必填");
          return false;
        }
        if (!formData.legalPersonPhone) {
          this.$Message.error("法人手机号码必填");
          return false;
        } else {
          if (formData.legalPersonPhone.indexOf("*") != -1) {
            console.log("手机号码有星号,判定为未做修改");
          } else {
            if (
              !/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(formData.legalPersonPhone)
            ) {
              this.$Message.error("请正确填写法人手机号码");
              return false;
            }
          }
        }
        if (!formData.idcardNo) {
          this.$Message.error("法人身份证号码必填");
          return false;
        } else {
          if (formData.idcardNo.indexOf("*") != -1) {
            // console.log("法人身份证号码有星号,判定为未做修改");
          } else {
            if (
              !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
                formData.idcardNo
              )
            ) {
              this.$Message.error("请输入合法的身份证号码");
              return false;
            }
          }
        }
        if (!formData.legalPersonCemStartDate) {
          this.$Message.error("法人证件开始日期必选");
          return false;
        }
        if (!formData.legalPersonCemEndDate) {
          this.$Message.error("法人证件结束日期必选");
          return false;
        }
        if (!formData.contactTel) {
          this.$Message.error("联系号码必填");
          return false;
        } else {
          if (formData.contactTel.indexOf("*") != -1) {
            console.log("手机号码有星号,判定为未做修改");
          } else {
            if (!/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(formData.contactTel)) {
              this.$Message.error("请正确填写联系号码");
              return false;
            }
          }
        }

        if (!formData.contactEmail) {
          this.$Message.error("联系邮箱必填");
          return false;
        } else {
          if (
            !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              formData.contactEmail
            )
          ) {
            this.$Message.error("联系邮箱格式不合法");
            return false;
          }
        }

        if (formData.mchType === "ENTERPRISE") {
          if (!formData.accountOpeningLicenceNo) {
            this.$Message.error("开户许可证编号必填");
            return false;
          }
        }
        if (!formData.licencePicture) {
          this.$Message.error("营业执照照片必传");
          return false;
        }
        if (!formData.idcardNormalPicture) {
          this.$Message.error("法人身份证国徽照片必传");
          return false;
        }
        if (!formData.idcardReversePicture) {
          this.$Message.error("法人身份证人像照片必传");
          return false;
        }
        if (!formData.accountOpeningLicense) {
          this.$Message.error("开户许可证照片必传");
          return false;
        }
      }

      if (tab === "tab3") {
        if (this.accountData.length === 0) {
          this.$Message.error("分账方信息必须添加，且只能添加一条");
          return false;
        }
      }

      return true;
    },
    nextStep(tab = "tab1") {
      switch (tab) {
        case "tab1":
          if (!this.validate("tab1")) {
            return;
          }
          this.curTab = "tab3";
          break;
        case "tab3":
          if (!this.validate("tab3")) {
            return;
          }
          this.curTab = "tab6";
          break;
      }
    },

    // 新增分账方
    addAccount() {
      this.addUserShow = true;
      this.mchFormData.mchName = this.formData.mchName;
      this.searAllocFn();
    },
    editAccount(row) {
      this.addUserShow = true;
      this.mchFormData = row;
      this.searAllocFn();
    },
    searAllocFn() {
      let mchName = this.detail.mchName;
      if (this.type === "add") {
        mchName = this.mchFormData.mchName;
      }
      searAlloc(mchName).then((res) => {
        if (res && res.resultStatus) {
          this.subNumber = res.resultData.mchAllocAccount;
        }
      });
    },
    sureAdd() {
      let data = Object.assign({}, this.mchFormData);
      console.log(data);
      this.subNumber.forEach((item) => {
        if (item.allocMchId == this.mchFormData.allocMchId) {
          data.allocMchNo = item.allocMchNo;
        }
      });

      if (!data.allocMchId) {
        this.$Message.error("分账方编号必填");
        return;
      }

      if (this.$route.query.id) {
        mchAllocEdit(this.$route.query.id, data).then((res) => {
          if (res && res.resultStatus) {
            this.$Message.success("保存成功");
            this.getDetail();
            this.addUserShow = false;
          }
        });
      } else {
        this.accountData = [data];
        this.addUserShow = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab-label {
  min-width: 120px;
  height: 38px;
  line-height: 38px;
  text-align: right;
  margin-right: 10px;
  white-space: nowrap;
  i {
    color: var(--themeColor);
    margin-right: 2px;
  }
}
.tab3-form {
  padding: 0 80px;
  .tab-label {
    width: 82px;
    min-width: 82px;
  }
}
.tab3-form-account {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  .tab-label {
    text-align: left;
  }
  /deep/ .ivu-form-item-content {
    flex-wrap: wrap;
    width: 200px;
  }
}
.container {
  overflow: hidden;
  background: #fff;

  .status {
    display: flex;
    padding: 10px 20px;
    background-color: rgba(249, 249, 249, 1);
    margin-bottom: 30px;
    font-size: 15px;
    .label {
      width: 120px;
      font-weight: 500;
      color: var(--label);
    }
    .check-msg {
      display: inline-block;
      width: 80%;
      color: var(--label);
      margin-left: 10px;
    }
  }
  .title {
    color: var(--label);
    padding-left: 0;
  }
  .edit-btn {
    font-size: 16px;
    width: 90px;
    height: 36px;
  }
  .table {
    min-height: 800px;
  }
  .tab1 {
    &-input {
      width: 100%;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(239, 239, 239, 1);
      border-radius: 4px;
      padding: 20px 30px 0px;
      .form {
        // overflow: hidden;
        height: 480px;
      }
      .form-item {
        float: left;
        width: 50%;
        display: flex;
        align-items: center;
        height: 40px;
        margin-bottom: 20px;
        .inputs {
          width: calc(100%);
          height: 36px;
          background: rgba(255, 255, 255, 1);
          border-radius: 4px;
          margin-left: 0;
        }
        .split {
          height: 40px;
          line-height: 40px;
          margin: 0 10px;
        }
        .date-picker {
          flex: 1;
        }
      }
      .form-address {
        display: flex;
        .tab-label {
          // width: 125px;
        }
        .form-date-picker {
          display: flex;
          width: calc(100%);
        }
        /deep/ .bottom-end .ivu-select-dropdown {
          left: auto !important;
          right: 0 !important;
        }
      }
      .input-box > li > span {
        margin-right: 10px;
        width: 150px;
        display: block;
        text-align: right;
      }
    }
    &-footer {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      margin-bottom: 18px;
      button {
        width: 120px;
      }
      button:last-child {
        margin-left: 10px;
      }
    }
    &-upload {
      overflow: hidden;
      width: 100%;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(239, 239, 239, 1);
      border-radius: 4px;
      padding: 20px 30px;
      margin-top: 20px;
      .upload-form-item {
        float: left;
        width: 140px;
        margin-right: 30px;
        span {
          white-space: nowrap;
        }
      }
      .upload-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 140px;
        position: relative;
        img {
          width: 100%;
        }
        .icon-delete {
          position: absolute;
          right: 0;
          top: 4px;
        }
      }
    }
  }
  .tab3 {
    .add-user-btn {
      display: block;
      margin-top: 10px;
    }
  }
  .attach-table {
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #eee;
    font-weight: 400;
    color: var(--label);
    th {
      font-weight: 400;
      padding: 30px 0;
      border: 1px solid #eee;
    }
    td {
      padding: 30px 0;
      border: 1px solid #eee;
      text-align: center;
    }
    .type {
      display: block;
      padding: 30px 0;
    }
    .content {
      width: 60%;
    }
  }
}
/deep/ .ivu-table:before {
  display: none;
}
.outer-div {
  padding: 24px;
  margin: 0 auto;
}
.header {
  height: 37px;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 24px;
}
ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tab-tilte {
  letter-spacing: 0;
  width: 90%;
}
.tab-tilte li {
  float: left;
  font-size: 14px;
  color: #515a6e;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
  span {
    padding: 0 12px;
  }
}
/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte .active {
  color: #3385ff;
  border-bottom: 2px solid #3385ff;
}
</style>
<style>
.ivu-tooltip-inner {
  max-width: none;
}
.ivu-form-item-label {
  width: 150px !important;
}
.ivu-form-item-content {
  display: flex;
  flex: 1;
  margin-left: 0 !important;
}
.tab3-form {
  padding: 0 20px;
}
.tab3 .ivu-form-item-label {
  width: 100px !important;
}
</style>
